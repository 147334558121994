import './user-info.scss';

customElements.define('user-info', class extends HTMLElement {
  constructor() {
    super();

    this._debugMode = false;
  }

  connectedCallback() {
    this._render();
  }

  _render() {
    this.insertAdjacentHTML('beforeend', this._template);
  }

  _remove() {
    this.parentElement.removeChild(this);
  }

  get _firstname() {
    return this.getAttribute('firstname');
  }

  get _lastname() {
    return this.getAttribute('lastname');
  }

  get _addressId() {
    return this.getAttribute('address-id');
  }

  get _userId() {
    return this.getAttribute('company-user-id');
  }

  get _size() {
    return parseInt(this.getAttribute('size'));
  }

  get _companyName() {
    return this.getAttribute('company-name');
  }

  get _dateTime() {
    return this.getAttribute('date-time');
  }

  get _hideDetails() {
    return this.hasAttribute('hide-details');
  }
  
  get _href() {
	  if (this.hasAttribute('href')) {
		  return `href="`+this.getAttribute('href')+`"`;
	  } else if (this.hasAttribute('address-id')){
		  return `href="/mundus-agri/addressDetails/${this._addressId}?tabid=3&cid=${this._userId}"`;
	  } else {
		  return ``;
	  }
  }

  get _template() {
    return `
      <a ${this._href} title="${this._firstname} ${this._lastname}">
        <div class="profile-image">
          ${this._firstname.substr(0, 1)}${this._lastname.substr(0, 1)}
          <span style="background-image:url(/images/profile_images/${this._userId}.png);"></span>
        </div>

        ${!this._hideDetails ? `
          <div class="details">
            <div class="user">${this._firstname} ${this._lastname}</div>
            ${this._companyName ? `<div class="company">${this._companyName}</div>` : ''}
            ${this._dateTime ? `<div class="date-time">${this._dateTime}</div>` : ''}
          </div>
        ` : ''}
      </a>
    `;
  }
});